import React, { Component } from 'react'
import Link from '../utils/link'
import smoothscroll from 'smoothscroll-polyfill'

import Logo from '../assets/images/PPHorizontal.svg'

class Header extends Component {

  state = {
    offCanvas: false
  }

  _hideOffCanvas = () => {
    this.setState({ offCanvas: false })
  }

  _toggleOffCanvas = () => {
    this.setState({ offCanvas: !this.state.offCanvas })
  }

  _scrollTo = (element) => {
    this.setState({ offCanvas: false })
    window && window.scroll({ 
      top: document && document.querySelector(element).offsetTop,
      left: 0,
      behavior: 'smooth'
    })
  }

  render() {

    let { offCanvas } = this.state

    let props = {
      onClick: this._hideOffCanvas,
      activeClassName: 'active'
    }

    return (
      <>
        <header className='header'>
          <div className='header__inner'>
            <Link to='/' title='Site Name' className='header__logo' {...props}>
              <img src={Logo} alt='PPHH Barbers' />
            </Link>
            <nav className='header__nav'>
              <ul>
                <li><button onClick={() => this._scrollTo('#services')}>Services</button></li>
                <li><button onClick={() => this._scrollTo('#barbers')}>Barbers</button></li>
                <li><button onClick={() => this._scrollTo('#barbers')}>Make a Booking</button></li>
                <li><button onClick={() => this._scrollTo('#contact')}>Contact</button></li>
              </ul>
            </nav>
          </div>
        </header>
        <div className={`off-canvas ${ offCanvas && 'off-canvas--active' }`}>
          <div className='off-canvas__inner'>
            <nav className='off-canvas__nav'>
              <ul>
                <li><button onClick={() => this._scrollTo('#services')}>Services</button></li>
                <li><button onClick={() => this._scrollTo('#barbers')}>Barbers</button></li>
                <li><button onClick={() => this._scrollTo('#barbers')}>Make a Booking</button></li>
                <li><button onClick={() => this._scrollTo('#contact')}>Contact</button></li>
              </ul>
            </nav>
          </div>
        </div>
      </>
    )
  }
}

export default Header
