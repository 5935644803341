import React from 'react';
import PropTypes from 'prop-types';
import {StaticQuery, graphql} from 'gatsby';

import '../assets/scss/main.scss';
import Header from '../components/header';
import Footer from '../components/footer';

const Layout = ({children, location}) => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
          }
        }
      }
    `}
    render={data => {
      return (
        <>
          {location.pathname !== '/thank-you' && location.pathname !== '/models' && <Header/>}
          <main>{children}</main>
          {location.pathname !== '/thank-you' && location.pathname !== '/models' && <Footer/>}
        </>
      );
    }
    }
  />
);

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
