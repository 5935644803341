import React, { Component } from 'react'
import Link from '../utils/link'

import Newsletter from './newsletter'

import Logo from '../assets/images/PPCentred.svg'
import PP from '../assets/images/pphh-logo.svg'

class Footer extends Component {

  _scrollTo = (element) => {
    this.setState({ offCanvas: false })
    window && window.scroll({ 
      top: document && document.querySelector(element).offsetTop,
      left: 0,
      behavior: 'smooth'
    })
  }

  render() {
    return (
      <footer className='footer' id='contact'>
        <div className='footer__inner'>
          <div className='footer__left'>
         {/*   <ul>
              <li>INFO</li>
              <li><button onClick={() => this._scrollTo('#services')}>Services</button></li>
              <li><button onClick={() => this._scrollTo('#barbers')}>Barbers</button></li>
              <li><button onClick={() => this._scrollTo('#hours')}>Opening Hours</button></li>
              <li><Link to='https://www.fresha.com/book-now/p-p-barbers-um8rh6sl/all-offer?pId=594167'>Make A Booking</Link></li>
            </ul>
            */}
            <ul>
              <li>P&P Barbers</li>
              <li>PPHH In-Store</li>
              <li>166 Gertrude Street</li>
              <li>Fitzroy VIC 3065 </li>
              <li>AUSTRALIA</li>
            </ul> 
            <ul>
              <li>P&P Barbershop</li>
              <li>116 Gertrude Street</li>
              <li>Fitzroy VIC 3065 </li>
              <li>AUSTRALIA</li>
            </ul>
          </div>
          <div className='footer__center'>
            <Link to='/' title='PP Barbers' className='footer__logo'>
              <img src={Logo} alt='P&amp;P Barbers' />
            </Link>
            <Link className='footer__phone' to='tel:+61394173390'>03 9417 3390</Link>
          </div>
          <div className='footer__right'>
            <ul>
              <li>GET SOCIAL</li>
              <li>
                <Link to='https://www.instagram.com/ppbarbers/'>Instagram</Link><br />
              </li>
            </ul>
            <Newsletter />
          </div>
          <div className='footer__credits'>
            <p className='first'>
              All rights reserved { new Date().getFullYear() }
              <span>Design by <Link to='https://atollon.com.au'>Atollon</Link></span>
            </p>
            <Link to='https://pphh.store' title='PP Barbers' className='footer__pp'>
              <img src={PP} alt='PP Barbers' />
            </Link>
            <p className='last'>Design by <Link to='https://atollon.com.au'>Atollon</Link></p>
          </div>
        </div>
      </footer>
    )
  }
}

export default Footer
